import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query IncidentReporting {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Incident Reporting',
    subtitle: `There's nothing quite as effective in progressing as an organization as learning from your mistakes. Incidents can be considered mistakes, so each incident should be cherished as an opportunity to investigate, assess and improve.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: "/features/incident-reporting/feature-page-header-incidents.png"
  }

  const seo = {
    title: 'Workhub | Free EHS Incident Reporting Software / Management System',
    description: 'Our Incident Reporting Software allows you to capture, analyze and investigate data on safety incidents , near misses & dangerous conditions so that you can take corrective & preventative action.',
    image: '/features/incident-reporting/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Reporting",
      para: "Log incidents in the workplace to collect statistics on the frequency and severity of incidents and near misses.",
    },
    {
      title: "Description",
      para: "Create a detailed report by uploading a description of the events, date, time, location, photos, linking assets and the environment or people involved.",
    },
    {
      title: "Injuries / Revised Work",
      para: "Reporters can log injuries to body parts on a human diagram and track an employee’s lost time and restricted work.",
    },
    {
      title: "Assign and Investigator",
      para: "Assign an investigator who can add root causes or attach additional information like witness statements, doctors’ reports, OSHA correspondence and more.",
    },
    {
      title: "Add Action Items",
      para: "Eliminate or mitigate each incident’s contributing factors by adding action items to any incident. This will ensure that corrective actions are carried out and signed off on.",
    },
    {
      title: "Charting",
      para: "Charts and graphs in the Admin panel show companywide trends in incident frequency, time lost, associated costs and more.",
    },

  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">





        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/incident-reporting/Incidents.png"
                    alt="Incident Reporting Cartoon"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h2>What if we could predict the future, or had a crystal ball?</h2>
                <p>Diligent reporting will provide meaningful statistics on the frequency and severity of incidents and near misses. Evaluating each for their root causes will provide invaluable insight into the unsafe work practices and behaviors. </p>
              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">


                <h2>An incident report starts with the basics!</h2>

                <p>A description of the events, along with key information like the date, time or location of an incident. Reporters have the option of adding photos, linking assets, the environment or people. People's involvement can be pretty broad, but they usually include injuries, responders, witnesses and such. In the case of injuries, the system will prompt reporters for the body part(s) affected and the injury type.  The system will also track lost time and restricted work.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/incident-reporting/sketch-mockup-incidents-worker-1.png"
                    alt="Tablet View"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">

                  <img
                    className="feature-img-right"
                    src="/features/incident-reporting/sketch-mockup-incidents-admin-2.png"
                    alt="Admin Computer"
                  />

                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h2>The root of the matter.</h2>

                <p>On the administrator side, you can assign an investigator who can then add Root Causes. Root causes can be Immediate or System types, which can be further categorized into Actions, Conditions, and Personal Factors.</p>

                <p>In the event there is more information that needs attaching to incidents (like say witness statements, doctor’s reports, OSHA correspondence and such), they’re easily linked to the incident.</p>

              </div>
            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 feature-text">

                <h2>Weed em' out.</h2>

                <p>Eliminate or mitigate each incident's contributing factors by adding action items to any incident. This will ensure that corrective actions are carried out (and signed off on) for any and all deficiencies. A safer workplace and better safety culture will be the reward.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/incident-reporting/sketch-mockup-incidents-worker-3.png"
                    alt="Worker Mobile"
                  />

                </ReactWOW>

              </div>


            </div>
          </div>
        </section>

        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>

    </Layout>
  )
}